<template>
  <div>
    <h4>
      افزودن دکتر
    </h4>
    <v-divider class="my-5"></v-divider>
    <v-form ref="assignFilter">
      <v-autocomplete
          v-model="doctor"
          :items="doctorList"
          item-text="full_name"
          item-value="doctor_profile.id"
          :rules="requiredRules"
      ></v-autocomplete>
      <v-select v-if="platform.id == 4" label="بسته دکتر" :items="packages" v-model="package_id" :rules="requiredRules"
                item-value="id"
                item-text="title"></v-select>
      <v-btn color="green" class="white--text font-weight-bold" small elevation="0" @click="assignDoctor">ثبت دکتر
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";
import {mapGetters} from "vuex";

export default {
  props: {
    profile: Object,
    userId: {
      default: null,
    },
    taskId: Number,
  },
  data() {
    return {
      doctor: null,
      doctorList: null,
      packages: [],
      package_id: null,
    }
  },
  mounted() {
    this.getDoctorList()
  },
  watch: {
    doctor() {
      if (this.platform.id == 4) {
        this.getDoctorPackages()
      }
    },
  },
  computed: {
    ...mapGetters({
      platform: 'getPlatform'
    }),
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
  },
  methods: {
    getDoctorPackages() {
      this.$store.dispatch('getAdminPackages', {
        options: {
          doctor_id: this.doctor,
          state: 'active'
        }, page: 1
      }).then((resp) => {
        this.packages = resp.data.packages
      })
    },
    getDoctorList() {
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('getDoctors').then((resp) => {
        this.doctorList = resp.data.doctors
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    assignDoctor() {
      if (this.$refs.assignFilter.validate()) {
        let payload = {
          user_id: this.userId || this.$route.params.clientId,
          doctor_id: this.doctor,
          status: "active",
          package_id: this.package_id
        }
        EventBus.$emit('setLoading', true)
        this.$store.dispatch('assignDoctor', payload).then(() => {
          EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
          this.$emit('callback')
          this.doctor = null
        }).finally(() => {
          EventBus.$emit('setLoading', false)
          this.$emit('closeModal')
        })
      }


    },

  }
}
</script>

<style scoped>

</style>