<template>
  <div>
    <v-container fluid style="background-color: #333">
      <v-row>
        <v-col cols="12">
          <v-card class="elevation-0" dark color="#222">
            <v-card-text class="text-center">
              <strong class="mx-1">کليه حقوق اين وب سایت متعلق به
                {{ platform.slug }}
                می باشد.</strong>
              <div>
                <span class="caption">{{ new Date().getFullYear() }}</span>
                <span class="mx-1">—</span>
                <v-icon small class="" color="#aaa">far fa-copyright</v-icon>
              </div>


            </v-card-text>
          </v-card>
        </v-col>
      </v-row>


    </v-container>
    <!--<v-card-->
    <!--flat-->
    <!--tile-->
    <!--dark-->
    <!--class=""-->
    <!--&gt;-->
    <!--<div class="pt-5 text-center">-->
    <!--در صورت بروز مشکل در فرایند ثبت سفارش، با پشتیبانی سیمیاروم در واتس اپ یا تلگرام به شماره-->
    <!--<v-btn rounded class="primary" small>-->
    <!--<span-->
    <!--style="display: inline-block;direction: ltr!important; text-align: left">-->
    <!--<a class="white&#45;&#45;text no-decoration"-->
    <!--href="whatsapp://send?phone=+989302672054">+989302672054</a>-->
    <!--</span>-->
    <!--</v-btn>-->
    <!--پیام ارسال فرمایید.-->
    <!--</div>-->

    <!--<v-card-text class="text-center">-->
    <!--<v-btn-->
    <!--v-for="(socialAccount,index) in socialAccounts"-->
    <!--:key="index"-->
    <!--class="mx-4 white&#45;&#45;text text-center justify-center"-->
    <!--socialAccount-->
    <!--:href="socialAccount.link"-->
    <!--target="_blank"-->
    <!--fab small-->
    <!--&gt;-->
    <!--<v-icon>{{ socialAccount.icon }}</v-icon>-->
    <!--</v-btn>-->
    <!--</v-card-text>-->


    <!--<v-divider></v-divider>-->

    <!--<v-card-text class="white&#45;&#45;text text-center">-->
    <!--{{ new Date().getFullYear() }}-->
    <!--<span class="mx-1">—</span>-->
    <!--<strong class="mx-1">مشاوره روانشناسی آنلاین</strong>-->
    <!--<v-icon small>far fa-copyright</v-icon>-->
    <!--</v-card-text>-->
    <!--</v-card>-->
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  props: {
    isSimiaroom: {
      default: true
    }
  },
  computed: {
    ...mapGetters({
      'platform': 'getPlatform',
    }),
    aboutUsTitle() {
      if (this.isSimiaroom) {
        return 'درباره سیمیاروم';
      } else {
        return 'درباره مشاور لایو';
      }


    },
    aboutUsText() {
      if (this.isSimiaroom) {
        return 'اگر از مشاوره روانشناسی آنلاین اطلاعات کمی دارید توصیه می کنیم راهنمای مشاوره های آنلاین روانشناسی را در لینک زیر مشاهده نمایید:';
      } else {
        return 'ارائه دهنده خدمات یکپارچه مشاوره ویدئویی برای روانشناسان و روانپزشکان';
      }
    }
  },
  data: () => ({
    simiaroom: [
      {title: 'بلاگ', href: 'https://simiaroom.com/blog/'},
      {title: 'مشاوران سیمیا', href: 'https://simiaroom.com/counselor/'},
      {title: 'تماس با ما', href: 'https://simiaroom.com/%d8%aa%d9%85%d8%a7%d8%b3-2/'},
      {title: 'درباره ما', href: 'https://simiaroom.com/%d8%af%d8%b1%d8%a8%d8%a7%d8%b1%d9%87-%d9%85%d8%a7/'},
    ],
    issues: [
      {title: 'مشاوره خانواده', href: 'https://simiaroom.com/blog/category/family-counselling/'},
      {
        title: 'زوج درمانی',
        href: 'https://simiaroom.com/counseling/%d9%85%d8%b4%d8%a7%d9%88%d8%b1%d9%87-%d8%a7%d8%b2%d8%af%d9%88%d8%a7%d8%ac/'
      },
      {title: 'مشاوره زناشویی', href: 'https://simiaroom.com/blog/category/marital-counseling/'},
      {title: 'مشاوره فردی', href: 'https://simiaroom.com/blog/category/individual-counseling/'},
      {
        title: 'مشاوره کودک و نوجوان',
        href: 'https://simiaroom.com/blog/category/child-and-adolescent-counselling/'
      },
      {title: 'حوزه های مشاوره', href: 'https://simiaroom.com/counseling/'},
    ],
    socialAccounts: [
      {
        icon: 'fab fa-facebook',
        link: 'https://www.facebook.com/simiatalk',
        color: 'blue darken-4'

      },
      {
        icon: 'fab fa-twitter',
        link: 'https://twitter.com/SimiaRoom',
        color: 'blue'

      },

      {
        icon: 'fab fa-linkedin',
        link: 'https://www.linkedin.com/company/simiaroom/',
        color: 'blue darken-2'

      },
      {
        icon: 'fab fa-instagram',
        link: 'https://www.instagram.com/simiaroomcom/',
        color: 'red lighten-1'
      },
      {
        icon: 'fab fa-youtube',
        link: 'https://www.youtube.com/channel/UCgM4KoG4MZg-ATBchGzQnAw/videos',
        color: 'red'
      },
    ],
  }),
}
</script>
<style>
.no-decoration {
  text-decoration: none !important;
}

.footer-links {
  color: #ccc !important;
  text-decoration: none;
  font-weight: bold;
  padding: 15px 10px;
  margin: 0;
  display: inline-block;
  width: 100%;
  border-radius: 15px;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.footer-links:hover {
  background-color: white;
  color: #222 !important;
  box-shadow: 0 10px 10px -7px rgba(0, 0, 0, .3);
  /*padding-right: 15px;*/
}

.footer-section-row {

}

.footer-link {
  text-decoration: none;
  color: #999 !important;
  /*padding: 15px 0;*/
}

.footer-link:hover {
  color: #222 !important;
}
</style>